import { Injectable } from '@angular/core';


@Injectable()
export class PersonalDataService {
  private bodyMassIndex: number;//ИМТ - индекс массы тела
  private basalMetabolism: number;//OO - Основной обмен
  private overallCalorieConsumption: number;//ОРЭ - Общий суточный расход энергии
  private energyCostsForTraining: number;//ЭТ - энергозатраты на тренировки

  public norm_calories: number;
  public norm_protein: number;
  public norm_fat: number;
  public norm_carbohydrate: number;

  private setCaloriesForTechniqueBalancedWeightLoss(work_type_id: number) {
    //console.log(this.overallCalorieConsumption * 0.76 - this.energyCostsForTraining, this.basalMetabolism);
    let cof = 0.76;
    if (work_type_id == 1)
      cof = 0.79;

    let rc_bottom;
    let rc_upper;
    let rc_minimum;

    //if (this.overallCalorieConsumption * cof - this.energyCostsForTraining >= this.basalMetabolism) {
    rc_bottom = this.overallCalorieConsumption * cof;
    rc_upper = this.overallCalorieConsumption * cof + this.energyCostsForTraining;
    rc_minimum = this.basalMetabolism;
    /*}
    else {
      let delta = this.energyCostsForTraining - (this.overallCalorieConsumption * cof - this.energyCostsForTraining);
      rc_bottom = this.overallCalorieConsumption * cof + delta;
      rc_upper = this.overallCalorieConsumption * cof + this.energyCostsForTraining;
      rc_minimum = this.basalMetabolism + delta;
      
    }*/
    /*
    console.log(this.basalMetabolism);
    console.log(this.energyCostsForTraining);
    console.log(this.overallCalorieConsumption);
    console.log('--------------------');
    console.log(rc_bottom);
    console.log(rc_minimum);
    console.log(rc_upper);
    console.log('////////////////////');
    console.log('////////////////////');*/
    return rc_bottom;
  }

  public setBodyMassIndex(weight:number, growth:number) {
    growth /= 100;
    this.bodyMassIndex = weight / (growth * growth);
  }

  public setBasalMetabolism(gender_id: number, weight: number, growth: number, birthday: string) {

    const age = Math.round(Math.abs((new Date(birthday).getTime() - new Date().getTime()) /
      (360 * 24 * 60 * 60 * 1000)));
    
    if (this.bodyMassIndex >= 25)
      weight = this.bodyMassIndex * (1 - (this.bodyMassIndex - 25) / 100) * (growth / 100) * (growth / 100);


    let _basalMetabolism = 10 * weight + 6.25 * growth + - 5 * age;
    if (gender_id == 1) _basalMetabolism += 5;
    else _basalMetabolism -= 161;

    
    this.basalMetabolism = _basalMetabolism;
  }

  public setOverallCalorieConsumption(work_type_id: number, body_type_id: number) {
    let activityCoefficientWork = 1;
    let activityCoefficientBody = 1;
    switch (work_type_id) {
      case 1:
        activityCoefficientWork = 1.3;
        break;
      case 2:
        activityCoefficientWork = 1.5;
        break;
      case 3:
        activityCoefficientWork = 1.7;
        break;
      case 4:
        activityCoefficientWork = 2;
        break;
      case 5:
        activityCoefficientWork = 2.3;
        break;
    }
    switch (body_type_id) {
      case 1:
        activityCoefficientBody = 1;
        break;
      case 2:
        activityCoefficientBody = 1.09;
        break;
      case 3:
        activityCoefficientBody = 1.15;
        break;
      case 4:
        activityCoefficientBody = 1.4;
        break;
    }
    this.energyCostsForTraining = this.basalMetabolism * (activityCoefficientBody-1);
    this.overallCalorieConsumption = this.basalMetabolism * activityCoefficientWork * activityCoefficientBody;
  }

  public techniqueBalancedWeightLoss(work_type_id: number) {//Методика сбалансированное похудение

    let recommendedCalories = this.setCaloriesForTechniqueBalancedWeightLoss(work_type_id);

    this.norm_calories = Math.round(recommendedCalories);
    this.norm_carbohydrate = Math.round(0.365 * recommendedCalories / 4);
    this.norm_fat = Math.round(0.395 * recommendedCalories / 9);
    this.norm_protein = Math.round(0.24 * recommendedCalories / 4);
  }

  public techniqueWeightGain() {//Методика "Набор веса"

    let recommendedCalories = this.overallCalorieConsumption + 0.2 * this.overallCalorieConsumption + this.energyCostsForTraining;
    let minimumRecommendedCalories = this.overallCalorieConsumption + this.energyCostsForTraining;

    //console.log(this.energyCostsForTraining,minimumRecommendedCalories);

    this.norm_calories = Math.round(recommendedCalories);
    this.norm_carbohydrate = Math.round(0.5 * recommendedCalories / 4);
    this.norm_fat = Math.round(0.3 * recommendedCalories / 9);
    this.norm_protein = Math.round(0.2 * recommendedCalories / 4);
  }
  public techniqueBalancedDiet() {//Методика "Сбалансированное питание"
    let recommendedCalories = this.overallCalorieConsumption + this.energyCostsForTraining;
    let minimumRecommendedCalories = this.basalMetabolism + this.energyCostsForTraining;

    //console.log(minimumRecommendedCalories);

    this.norm_calories = Math.round(recommendedCalories);
    this.norm_carbohydrate = Math.round(0.52 * recommendedCalories / 4);
    this.norm_fat = Math.round(0.3 * recommendedCalories / 9);
    this.norm_protein = Math.round(0.18 * recommendedCalories / 4);
  }

  public techniqueModerateKetoDiet(work_type_id: number) {//Методика Умеренная кето-диета
    
    let recommendedCalories = this.setCaloriesForTechniqueBalancedWeightLoss(work_type_id);

    this.norm_calories = Math.round(recommendedCalories);
    let _norm_carbohydrate;

    _norm_carbohydrate = Math.round(0.1 * recommendedCalories / 4);
    if (_norm_carbohydrate > 50)
      this.norm_carbohydrate = 50;
    
    this.norm_protein = Math.round(0.18 * recommendedCalories / 4);

    this.norm_fat = Math.round((recommendedCalories - this.norm_protein * 4 - this.norm_carbohydrate*4) / 9);
  }

  public techniqueHighProteinDiet(work_type_id: number) {//Методика Высокобелковая диета

    let recommendedCalories = this.setCaloriesForTechniqueBalancedWeightLoss(work_type_id);

    this.norm_calories = Math.round(recommendedCalories);
    this.norm_carbohydrate = Math.round(0.3 * recommendedCalories / 4);
    this.norm_fat = Math.round(0.3 * recommendedCalories / 9);
    this.norm_protein = Math.round(0.4 * recommendedCalories / 4);
  }

  public techniqueLowFatDiet(work_type_id: number) {//Методика Низкожировая диета

    let recommendedCalories = this.setCaloriesForTechniqueBalancedWeightLoss(work_type_id);

    this.norm_calories = Math.round(recommendedCalories);
    this.norm_carbohydrate = Math.round(0.55 * recommendedCalories / 4);
    this.norm_fat = Math.round(0.25 * recommendedCalories / 9);
    this.norm_protein = Math.round(0.2 * recommendedCalories / 4);
  }

  public techniqueLowCarbDiet(work_type_id: number) {//Методика Низкоуглеводная диета

    let recommendedCalories = this.setCaloriesForTechniqueBalancedWeightLoss(work_type_id);

    this.norm_calories = Math.round(recommendedCalories);
    this.norm_carbohydrate = Math.round(0.25 * recommendedCalories / 4);
    this.norm_fat = Math.round(0.4 * recommendedCalories / 9);
    this.norm_protein = Math.round(0.35 * recommendedCalories / 4);
  }

  public calculateTheRate(dataForm) {
    this.setBodyMassIndex(
      dataForm.weight.value,
      dataForm.growth.value
    );
    this.setBasalMetabolism(
      dataForm.gender_id.value,
      dataForm.weight.value,
      dataForm.growth.value,
      dataForm.birthday.value
    );
    this.setOverallCalorieConsumption(
      dataForm.work_type_id.value,
      dataForm.body_type_id.value
    );
    switch (dataForm.aim_id.value) {
      case 1:
        this.techniqueBalancedWeightLoss(
          dataForm.work_type_id.value
        );
        break;
      case 2:
        this.techniqueWeightGain();
        break;
      case 3:
        this.techniqueBalancedDiet();
        break;
      case 4:
        this.techniqueModerateKetoDiet(
          dataForm.work_type_id.value
        );
        break;
      case 5:
        this.techniqueHighProteinDiet(
          dataForm.work_type_id.value
        );
        break;
      case 6:
        this.techniqueLowFatDiet(
          dataForm.work_type_id.value
        );
        break;
      case 7:
        this.techniqueLowCarbDiet(
          dataForm.work_type_id.value
        );
        break;
    }
  }
}
