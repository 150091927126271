import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountAuthorizationPageComponent, HomeComponent } from './components';
import { FoodDiaryPageComponent } from './components/food-diary';
import { PersonalDataPageComponent } from './components/personal-data/personal-data-page.component';
import { AccountGuard } from './services/account.guard';
import { NotFoundPageComponent } from './components/not-found/not-found-page.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "auth", component: AccountAuthorizationPageComponent },
  { path: "food-diary", component: FoodDiaryPageComponent, canActivate: [AccountGuard] },
  { path: "food-diary/:date", component: FoodDiaryPageComponent, canActivate: [AccountGuard] },
  { path: "personal-data", component: PersonalDataPageComponent, canActivate: [AccountGuard] },
  { path: "**", component: NotFoundPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AccountGuard]
})
export class AppRoutingModule { }
