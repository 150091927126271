import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services';


@Component({
  selector: "home-page",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.less"]
})

export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    if (this.accountService.currentUserValue)
      this.router.navigate(['food-diary']);
  }
}
