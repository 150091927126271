
export class ApiData<T> {
  error: ApiDataError;
  data: T;
}

export class ApiDataError {
  code: number;
  text: string;
}
