import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import {
  HomeComponent,
  NotFoundPageComponent,
  PersonalDataPageModule,
  FoodDiaryPageModule
} from './components';

import { SharedModule } from './shared';

import { AccountService, ConfigurationService, OtherService } from './services';

import { ToastrModule } from 'ngx-toastr';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AccountModule } from './components/account/account.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FoodDiaryPageModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    PersonalDataPageModule,
    AccountModule,
    ToastrModule.forRoot(),
    FlexLayoutModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    AccountService, ConfigurationService, OtherService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
