import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AccountAuthorizationPageComponent } from './authorization-page.component';
import { AccountLoginComponent } from './login.component';
import { AccountRegistrationComponent } from './registration.component';
import { AccountDialogPrivacyPolicyComponent } from './dialog-privacy-policy.component';
import { AccountRestorePasswordComponent } from './restore-password.component';


@NgModule({
  declarations: [
    AccountAuthorizationPageComponent,
    AccountDialogPrivacyPolicyComponent,
    AccountLoginComponent,
    AccountRegistrationComponent,
    AccountRestorePasswordComponent
  ],
  entryComponents: [
    AccountDialogPrivacyPolicyComponent
  ],
  imports: [
    FlexLayoutModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ]
})

export class AccountModule { }
