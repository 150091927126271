import { Component, Input, ChangeDetectorRef } from "@angular/core";
import { MatTableDataSource } from '@angular/material';
import { FoodDiaryTable, PersonalDataNorm } from 'src/app/models';

@Component({
  selector: 'report-table-total',
  templateUrl: './table-total.component.html',
  styleUrls: ['./table-total.component.less']
})

export class ReportTotalComponent {
  private _total: FoodDiaryTable = new FoodDiaryTable();
  private _norm: FoodDiaryTable = new FoodDiaryTable();
  @Input() set total(total: FoodDiaryTable) {
    //if (total.calories)
      this._total = total;

    this.setData();
  }
  @Input() set norm(norm: PersonalDataNorm) {
    //if (norm.calories)
      this._norm = new FoodDiaryTable(
        1, "Суточная норма", 0, norm.calories, norm.carbohydrate, norm.protein, norm.fat
      );

    this.setData();
  }

  public displayedColumns: string[] = [
    'name',
    'weight',
    'calories',
    'protein',
    'carbohydrate',
    'fat'
  ];

  public dataSource: MatTableDataSource<FoodDiaryTable> = new MatTableDataSource();

  constructor(private changeDetectorRefs: ChangeDetectorRef) { }

  private setData() {
    let percent: FoodDiaryTable = new FoodDiaryTable();

    if (this._total.calories && this._norm.calories) {

      percent = new FoodDiaryTable(
          2, "выполнено на %", 0,
          Math.round(this._total.calories / this._norm.calories * 100),
          Math.round(this._total.carbohydrate / this._norm.carbohydrate * 100),
          Math.round(this._total.protein / this._norm.protein * 100),
          Math.round(this._total.fat / this._norm.fat * 100),
      );
    }

    this.dataSource.data = [this._total, this._norm, percent];

    this.changeDetectorRefs.detectChanges();
    
  }
}
