import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigurationService } from 'src/app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.less']
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;

  public loadingIndicator: boolean = false;

  constructor(
    private configurationService: ConfigurationService
  ) { }

  ngOnInit() {
    this._subscription = this.configurationService.getLoadingIndicator().subscribe(
      (status: boolean) => this.loadingIndicator = status
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
