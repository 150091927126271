import { Component, OnInit, Input } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { FoodDiaryTable } from 'src/app/models';

@Component({
  selector: 'chart-bfc-calorie-ration',
  templateUrl: './chart-bfc-calorie-ratio.component.html',
  styleUrls: ['./chart-bfc-calorie-ratio.component.less']
})

export class ChartBfcCalorieRationComponent implements OnInit {
  @Input() set totalDay(totalDay: FoodDiaryTable) {
    let _pieChartData: number[] = [];
    _pieChartData.push(totalDay.protein);
    _pieChartData.push(totalDay.fat);
    _pieChartData.push(totalDay.carbohydrate);

    this.ratioBase = 0;
    _pieChartData.forEach(item => { if (item > 0) this.ratioBase = item; } );

    this.pieChartData = _pieChartData;
  }

  private ratioBase: number = 0;

  public pieChartOptions: ChartOptions;
  public pieChartColors;
  public pieChartLabels: Label[] = ['Белки', 'Жиры', 'Углеводы'];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  
  constructor() { }

  ngOnInit() {
    this.pieChartOptions = {
      responsive: true,
      legend: {
        position: 'top',
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            return label;
          },
        },
      }
    };

    this.pieChartColors = [
      {
        backgroundColor: ['rgba(255,0,0,0.5)', 'rgba(0,255,0,0.5)', 'rgba(0,0,255,0.5)'],
      },
    ];
  }

  get rationProtein() {
    return (this.pieChartData[0] / this.ratioBase).toFixed(1);
  }
  get rationFat() {
    return (this.pieChartData[1] / this.ratioBase).toFixed(1);
  }
  get rationCarbohydrate() {
    return (this.pieChartData[2] / this.ratioBase).toFixed(1);
  }
}
