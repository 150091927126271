import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountService } from '../services';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AccountService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let currentUser = this.authenticationService.currentUserValue;

      if (currentUser && currentUser.token) {
          request = request.clone({
              setHeaders: { 
                  Authorization: `bearer-${currentUser.token}`
              }
          });
      }
      return next.handle(request);
    }
}
