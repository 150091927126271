import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDatepickerInputEvent, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { APP_DATE_FORMATS } from 'src/app/helpers/app-date.adapter';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountService, ConfigurationService } from 'src/app/services';
import { ApiData, PersonalData } from 'src/app/models';
import { PersonalDataService } from 'src/app/services/personal-data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ScrollableDirective, OffsetTopDirective } from 'src/app/directives';

@Component({
  selector: "personal-data-page",
  templateUrl: "./personal-data-page.component.html",
  styleUrls: ["./personal-data-page.component.less"],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class PersonalDataPageComponent implements OnInit {
  @ViewChild(OffsetTopDirective, { static: false }) normBox: OffsetTopDirective;
  @ViewChild(ScrollableDirective, { static: false }) page: ScrollableDirective;

  public personalDataForm: FormGroup;
  public normForm: FormGroup;

  public isUpdateNorm: boolean = false;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private accountService: AccountService,
    private personalDataService: PersonalDataService,
    private configurationService: ConfigurationService
  ) {
    this.dateAdapter.setLocale('ru');

    this.personalDataForm = new FormGroup({
      "gender_id": new FormControl("", Validators.required),
      "birthday": new FormControl("", Validators.required),
      "growth": new FormControl("", Validators.required),
      "weight": new FormControl("", Validators.required),
      "aim_id": new FormControl("", Validators.required),
      "work_type_id": new FormControl("", Validators.required),
      "body_type_id": new FormControl("", Validators.required)
    });
    this.normForm = new FormGroup({
      "norm_calories": new FormControl("", Validators.required),
      "norm_carbohydrate": new FormControl("", Validators.required),
      "norm_fat": new FormControl("", Validators.required),
      "norm_protein": new FormControl("", Validators.required)
    });
  }

  ngOnInit() {
    setTimeout(() => this.configurationService.setLoadingIndicator(true));
    
    this.loadPersonalData();
  }

  private loadPersonalData() {
    this.accountService.getPersonalData().subscribe(
      (data: ApiData<PersonalData>) => {
        this.personalDataForm.controls.gender_id.setValue(data.data.gender_id);
        this.personalDataForm.controls.birthday.setValue(data.data.birthday);
        this.personalDataForm.controls.growth.setValue(data.data.growth);
        this.personalDataForm.controls.weight.setValue(data.data.weight);
        this.personalDataForm.controls.aim_id.setValue(data.data.aim_id);
        this.personalDataForm.controls.work_type_id.setValue(data.data.work_type_id);
        this.personalDataForm.controls.body_type_id.setValue(data.data.body_type_id);

        this.normForm.controls.norm_calories.setValue(data.data.norm_calories);
        this.normForm.controls.norm_carbohydrate.setValue(data.data.norm_carbohydrate);
        this.normForm.controls.norm_fat.setValue(data.data.norm_fat);
        this.normForm.controls.norm_protein.setValue(data.data.norm_protein);

        setTimeout(() => this.configurationService.setLoadingIndicator(false));
      },
      (error: HttpErrorResponse) => this.configurationService.httpErrorResponse(error)
    );
  }

  public onDateChange(event: MatDatepickerInputEvent<Date>) {
    console.log(event);
  }

  public dateClass = (d: Date) => {
    return undefined;
  }

  public savePersonalData() {

    let data: any = this.personalDataForm.value;

    if (this.isUpdateNorm) {
      this.personalDataService.calculateTheRate(this.personalDataForm.controls);
    
      this.normForm.controls.norm_calories.setValue(this.personalDataService.norm_calories);
      this.normForm.controls.norm_carbohydrate.setValue(this.personalDataService.norm_carbohydrate);
      this.normForm.controls.norm_fat.setValue(this.personalDataService.norm_fat);
      this.normForm.controls.norm_protein.setValue(this.personalDataService.norm_protein);


      data['norm_calories'] = this.personalDataService.norm_calories;
      data['norm_carbohydrate'] = this.personalDataService.norm_carbohydrate;
      data['norm_fat'] = this.personalDataService.norm_fat;
      data['norm_protein'] = this.personalDataService.norm_protein;
    }

    this.accountService.updatePersonalData(data, this.isUpdateNorm).subscribe(
      (data: ApiData<string>) => {
        if (data.data == "success") {

          let message = '';
          if (this.isUpdateNorm) {
            this.page.scrollTop = this.normBox.offsetTop - 56;//56 - toolbar
            message = 'Суточная норма обновлена';
          }

          this.configurationService.showMessage("success", "Успешно сохранено",message);
        }
          
      },
      (error: HttpErrorResponse) => this.configurationService.httpErrorResponse(error),
      () => this.isUpdateNorm = false
    );
    
  }

  public saveNorm() {
    let norm = this.normForm.controls;

    this.accountService.updatePersonalDataNorm(
      norm.norm_calories.value,
      norm.norm_carbohydrate.value,
      norm.norm_fat.value,
      norm.norm_protein.value,
    ).subscribe(
      (data: ApiData<string>) => {
        if (data.data == "success")
          this.configurationService.showMessage("success", "Успешно сохранено");
      },
      (error: HttpErrorResponse) => this.configurationService.httpErrorResponse(error)
    );
  }

  public setUpdateNormTrue() {
    this.isUpdateNorm = true;
  }
}
