import { Product } from './product.model';
import { FormControl } from '@angular/forms';

//тип для матеріл табле (тіпа що б працювала сортіровка тип співпадати має із полями)
export class FoodDiaryTable {
  public id: number = 0;
  public name: string = '';
  public weight: number = 0;
  public calories: number = 0;
  public carbohydrate: number = 0;
  public protein: number = 0;
  public fat: number = 0;

  constructor(
    id?: number,
    name?: string,
    weight?: number,
    calories?: number,
    carbohydrate?: number,
    protein?: number,
    fat?: number
  ) {
    if (id) {
      this.id = id;
      this.name = name;
      this.weight = weight;
      this.calories = calories;
      this.carbohydrate = carbohydrate;
      this.protein = protein;
      this.fat = fat;
    }
  }
}

//тип для таблиці
export class FoodDiaryMealTypes {
  public id: number;
  public name: string;
  public sort: number;
}

//Тип для всіх таблиць (setTotal перенести в service)
export class FoodDiaryTables {
  public id: number;
  public name: string;
  public sort: number;
  public meal_id: number;
  public products: FoodDiaryTableProduct[];
  public total: FoodDiaryTable;

  constructor(
    id?: number,
    name?: string,
    sort?: number,
    meal_id?: number,
    products?: FoodDiaryTableProduct[],
    total?: FoodDiaryTable
  ) {
    this.id = id;
    this.name = name;
    this.sort = sort;
    this.meal_id = meal_id;
    this.products = products;
    this.total = total;
    //this.setTotal();
  }
  
}

//Тип для массива загально сам продукт к таблиці
export class FoodDiaryTableProduct {
  id: number;
  meal_id: number;
  weight: number;
  product_info: Product;
  meal_type_id?: number;
  constructor(
    id?: number,
    weight?: number,
    product_info?: Product,
    meal_type_id?: number,
    meal_id?: number
  ) {
    this.id = id;
    this.weight = weight;
    this.product_info = product_info;
    this.meal_type_id = meal_type_id;
    this.meal_id = meal_id;
  }
}

//тип для добавлення продукта в таблицю для api
export class FoodDiaryAddProductApi{
  date: string;
  product_id: number;
  weight: number;
  meal_type_id: number;

  constructor(
    date: string,
    product_id: number,
    weight: number,
    meal_type_id: number
  ) {
    this.date = date;
    this.product_id = product_id;
    this.weight = weight;
    this.meal_type_id = meal_type_id;
  }
}

//тип даних для получення від api
export class FoodDiaryTableFromApi {
  id: number;
  meal_type_id: number;
  products: Product[];
}

export class FoodDiaryReportNormStatus {
  date: string;
  calories: number;
  protein: number;
  fat: number;
  carbohydrate: number;
}

export class FoodDiaryDayStatus {
  date: string;
  status: number;

  constructor(date: string, status: number) {
    this.date = date;
    this.status = status;
  }
}

export interface DialogDataCopyFoodTable {
  id: number;
  mealTypes: FoodDiaryMealTypes[];
  date: FormControl;
  meal_id: number;
}
