import { Component, Input } from "@angular/core";
import { MatTableDataSource } from '@angular/material';
import { FoodDiaryTable } from 'src/app/models';

@Component({
  selector: 'food-diary-product-total',
  templateUrl: './total.component.html',
  styleUrls: ['./total.component.less']
})

export class FoodDiaryProductTotalComponent {
  @Input() set data(data: FoodDiaryTable) {
    this.dataSource.data = [data];
  }

  public displayedColumns: string[] = [
    'name',
    'weight',
    'calories',
    'protein',
    'carbohydrate',
    'fat'
  ];

  public dataSource: MatTableDataSource<FoodDiaryTable> = new MatTableDataSource();

  constructor() { }

}
