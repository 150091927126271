import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiData } from 'src/app/models';
import { AccountService, ConfigurationService } from 'src/app/services';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.less']
})

export class AccountRestorePasswordComponent {
  public restoreForm: FormGroup;
  private formSumitAttempt: boolean;
  public isSuccess: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private configurationService: ConfigurationService
  ) {
  }

  ngOnInit() {

    this.restoreForm = this.formBuilder.group({
      email: ['', Validators.required]
    });

  }

  get f() { return this.restoreForm.controls; }

  isFieldValid(field: string) {
    return (
      (!this.restoreForm.get(field).valid && this.restoreForm.get(field).touched) ||
      (this.restoreForm.get(field).untouched && this.formSumitAttempt && !this.restoreForm.get(field).valid)
    );
  }

  public onSubmitRestore() {
    this.formSumitAttempt = true;

    if (this.restoreForm.valid) {

      this.configurationService.setLoadingIndicator(true);

      this.accountService.restorePassword(this.f.email.value).subscribe((result: ApiData<string>) => {
        if (result.data === 'success') {
          this.configurationService.showMessage('success', 'На вашу почту отправлено письмо с новым паролем');
        } else if (result.error) {
          this.configurationService.showMessage('error', result.error.text);
        }

        setTimeout(() => this.configurationService.setLoadingIndicator(false));
      },
      (error: HttpErrorResponse) => this.configurationService.httpErrorResponse(error)
      );
    }
  }

}
