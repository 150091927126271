export * from './add.component';
export * from './table.component';
export * from './total.component';
export * from './tables.component';
export * from './add-list.component';
export * from './add-form.component';
export * from './add-filter.component';
export * from './add-categories.component';
export * from './table-menu.component';
export * from './table-item-menu.component';
export * from './dialog-copy-table.component';
