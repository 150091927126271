import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormControl } from '@angular/forms';
import { FoodDiaryApiService } from '../../../services';
import { Product } from '../../../models';

@Component({
  selector: 'food-diary-product-add-filter',
  templateUrl: './add-filter.component.html',
  styleUrls: ['./add-filter.component.less']
})

export class FoodDiaryProductAddFilterComponent implements OnInit {
  @Output() products: EventEmitter<Product[]> = new EventEmitter<Product[]>();

  public searchTerm: FormControl = new FormControl();

  constructor(
    private foodDiaryApiService: FoodDiaryApiService
  ) { }

  ngOnInit() {
    this.searchTerm.valueChanges.subscribe(
      term => {
        if (term != '') {
          this.foodDiaryApiService.search(term).subscribe(
            data => {
              this.products.emit(data as any[]);
            })
        }
        else {
          this.products.emit(null);
        }
      })
  }

  public eventClearInput() {
    this.searchTerm.setValue('');
    this.products.emit(null);
  }

  get showBtnClear() {
    if (this.searchTerm.value)
      return true;
    else
      return false;
  }
}
