import { Injectable } from "@angular/core";
import { Subject, Observable } from 'rxjs';
import { FoodDiaryApiService } from './food-diary-api.service';
import { FoodDiaryMealTypes, ApiData, ApiDataError, FoodDiaryTableFromApi, FoodDiaryTables, FoodDiaryTable, FoodDiaryTableProduct, FoodDiaryAddProductApi, FoodDiaryReportNormStatus, FoodDiaryDayStatus, PersonalDataNorm } from '../models';
import { ConfigurationService } from './configuration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountService } from './account.service';


@Injectable()
export class FoodDiaryService {
  private _tables: FoodDiaryTables[];
  private _totalDay: FoodDiaryTable = new FoodDiaryTable();
  private _subjectTables = new Subject<FoodDiaryTables[]>();
  private _subjectMealTypes = new Subject<FoodDiaryMealTypes[]>();
  private _subjectTotalDay = new Subject<FoodDiaryTable>();
  private _subjectTotalAllDays = new Subject<FoodDiaryDayStatus[]>();
  private _productKeys: string[] = ['calories', 'carbohydrate', 'fat', 'protein'];
  private _totalKeys: string[] = ['weight', 'calories', 'carbohydrate', 'fat', 'protein'];

  constructor(
    private foodDiaryApiService: FoodDiaryApiService,
    private configurationService: ConfigurationService,
    private accountService: AccountService
  ) { }

  public loadCalorieAndPfcNormByDays() {
    
    this.foodDiaryApiService.getCalorieAndPfcNormByDays().subscribe(
      (data: ApiData<FoodDiaryReportNormStatus[]>) => {
        
        this.accountService.getNorm().subscribe(
          (norm: ApiData<PersonalDataNorm>) => {
            
            let _result: FoodDiaryDayStatus[] = [];
          
            data.data.forEach(d => {

              let status = null;

              let calories = d.calories / norm.data.calories * 100;
              let carbohydrate = d.carbohydrate / norm.data.carbohydrate * 100;
              let fat = d.fat / norm.data.fat * 100;
              let protein = d.protein / norm.data.protein * 100;

              let sum = (calories + carbohydrate + fat + protein) / 4;

              if (sum > 20 && sum <= 40 || sum > 180)
                status = "bad";
              else if (sum > 40 && sum <= 70 || sum > 140 && sum<=180)
                status = "norm";
              else if (sum > 80 && sum <= 90 || sum > 110 && sum <= 140)
                status = "fine";
              else if (sum > 90 && sum <= 110)
                status = "perfect";

              _result.push(new FoodDiaryDayStatus(d.date, status));
            });

            this._subjectTotalAllDays.next(_result);

          },
          (error: ApiDataError) => console.log(error)
        );
      },
      (error: HttpErrorResponse) => this.configurationService.httpErrorResponse(error)
    );

  }

  public loadTables(date: Date, mealTypes: FoodDiaryMealTypes[]) {
    this.configurationService.setLoadingIndicator(true);
    const strDate = this.getStrDate(date);

    this.foodDiaryApiService.getTables(strDate).subscribe(
      (data: ApiData<FoodDiaryTableFromApi[]>) => {
        let tables: FoodDiaryTables[] = [];

        mealTypes.forEach(
          (item) => {
            const meal = data.data.find((d) => d.meal_type_id == item.id);
            let products = [];
            let meal_id = 0;

            if (meal) {
              products = meal.products;
              meal_id = meal.id;
            }
            
            tables.push(new FoodDiaryTables(
              item.id, item.name, item.sort, meal_id, products,
              this.setTotalTable(products, true)
            ));
          }
        );
        this._tables = tables;
        this.setTotalDay();
        this._subjectTables.next(tables);
        this.configurationService.setLoadingIndicator(false);
      },
      (error: HttpErrorResponse) => this.configurationService.httpErrorResponse(error)
    );
      
  }

  public loadMealTypes() {
    this.foodDiaryApiService.getMealTypes().subscribe(
      (data: ApiData<FoodDiaryMealTypes[]>) => {
        this._subjectMealTypes.next(data.data);
      },
      (error: HttpErrorResponse) => this.configurationService.httpErrorResponse(error)
    );

  }

  public addProductInTable(newProduct: FoodDiaryAddProductApi) {
    this.configurationService.setLoadingIndicator(true);

    this.foodDiaryApiService.addProducts(newProduct).subscribe(
      (data: ApiData<FoodDiaryTableProduct>) => {
        const index = this.getTableIndex(newProduct.meal_type_id);
        this._tables[index].products.push(this.updateValueByWeight(data.data));
        if (this._tables[index].meal_id==0)
          this._tables[index].meal_id = data.data.meal_id;

        this.resetTable(index);
        this.configurationService.showMessage("success", "Вы добавили продукт", data.data.product_info.name);
      },
      (error: HttpErrorResponse) => this.configurationService.httpErrorResponse(error)
    );
  }

  public deleteProductInTable(productId: number, tableId: number) {
    this.configurationService.setLoadingIndicator(true);
    this.foodDiaryApiService.deleteProductInTable(productId).subscribe(
      (data: ApiData<string>) => {
        
        const index = this.getTableIndex(tableId);

        const productIndex = this._tables[index].products.findIndex(d => d.id == productId);
        this.configurationService.showMessage("success", "Продукт удален", this._tables[index].products[productIndex].product_info.name);

        this._tables[index].products.splice(productIndex, 1);

        this.resetTable(index);

      },
      (error: HttpErrorResponse) => this.configurationService.httpErrorResponse(error)
    );
  }

  public getTables(): Observable<FoodDiaryTables[]> {
    return this._subjectTables.asObservable();
  }

  public getMealTypes(): Observable<FoodDiaryMealTypes[]> {
    return this._subjectMealTypes.asObservable();
  }

  public getTotalDay(): Observable<FoodDiaryTable> {
    return this._subjectTotalDay.asObservable();
  }

  public getTotalAllDays(): Observable<FoodDiaryDayStatus[]> {
    return this._subjectTotalAllDays.asObservable();
  }

  public get totalDay() {
    return this._totalDay;
  }

  public getStrDate(date: Date) {
    const result = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return result;
  }
  public getBeautifyDate(date: Date) {
    const result = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
    return result;
  }

  public tableFormatting(data: FoodDiaryTableProduct[]): FoodDiaryTable[] {
    let result: FoodDiaryTable[] = [];

    data.forEach((d: FoodDiaryTableProduct) => {
      result.push(new FoodDiaryTable(
        d.id,
        d.product_info.name, d.weight, d.product_info.calories,
        d.product_info.carbohydrate, d.product_info.protein, d.product_info.fat
      ));
    });

    return result;
  }

  public updateWeightProductInTable(id: number, weight: number, meal_type_id: number) {
    this.foodDiaryApiService.updateWeightProductInTable(id, weight).subscribe(
      (data: ApiData<string>) => {

        const index = this.getTableIndex(meal_type_id);
        
        this._tables[index].products.map((item: FoodDiaryTableProduct) => {
          if (item.id == id) {
            let _weight = item.weight;
            item.weight = weight;
            item = this.updateValueByWeight(item, _weight);
            this.configurationService.showMessage("success", "Масса продукта обновлена", item.product_info.name);
          }

        });

        this.resetTable(index);
      },
      (error: HttpErrorResponse) => this.configurationService.httpErrorResponse(error)
    );
  }

  private resetTable(index:number) {
    this._tables[index].total = this.setTotalTable(this._tables[index].products);
    const t = this._tables[index];

    this._tables.splice(index, 1);
    this._tables.push(
      new FoodDiaryTables(
        t.id, t.name, t.sort, t.meal_id, t.products, t.total
      )
    );

    this.setTotalDay();
    this._subjectTables.next(this._tables);
    this.configurationService.setLoadingIndicator(false);
  }

  private setTotalDay() {
    let total: FoodDiaryTable = new FoodDiaryTable();
    this._tables.forEach((table: FoodDiaryTables) =>
      this._totalKeys.map(key => total[key] += table.total[key])
    );

    this._totalKeys.map(key => total[key] = Number(total[key].toFixed(2)));
    this._totalDay = total;

    this._subjectTotalDay.next(total);
  }

  private setTotalTable(products: FoodDiaryTableProduct[], isUpdateProductValue?: boolean) {
    let total: FoodDiaryTable = new FoodDiaryTable();

    products.forEach((product: FoodDiaryTableProduct) => {
      if (isUpdateProductValue)
        product = this.updateValueByWeight(product);

      this._productKeys.map(key => {
        total[key] += product.product_info[key];
      });

      total.weight += product.weight;
    });

    this._productKeys.map(key => total[key] = Number(total[key].toFixed(2)));
    
    total.weight = Number(total.weight.toFixed(2));

    return total;
  }

  private updateValueByWeight(product, defaultWeight?: number) {
    if (!defaultWeight)
      defaultWeight = 100;

    this._productKeys.map(key => {
      product.product_info[key] = Number(
        ((product.product_info[key] / defaultWeight) * product.weight).toFixed(2)
      );
    });
    return product;
  }

  private getTableIndex(tableId:number) {
    return this._tables.findIndex((table: FoodDiaryTables) => table.id == tableId);
  }

}
