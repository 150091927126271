import { NgModule } from "@angular/core";
import { AsideMenuComponent, AsideUserComponent } from './aside';
import { FormFieldErrorDisplayComponent } from './form/field-error-display.component';
import { LoadingIndicatorComponent } from './helpers/loading-indicator.component';
import { MaterialModule } from './material.module';
import { ChangeValueComponent } from './form/change-value.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogAskQuestionComponent } from './dialog/ask-question.component';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [
    AsideMenuComponent,
    AsideUserComponent,
    FormFieldErrorDisplayComponent,
    LoadingIndicatorComponent,
    ChangeValueComponent,
    DialogAskQuestionComponent
  ],
  exports: [
    MaterialModule,
    LoadingIndicatorComponent,
    AsideMenuComponent,
    AsideUserComponent,
    FormFieldErrorDisplayComponent,
    ChangeValueComponent
  ],
  entryComponents: [
    DialogAskQuestionComponent
  ],
  imports: [
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    FlexLayoutModule
  ]
})
export class SharedModule { }
