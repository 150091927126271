import { Component, Input } from "@angular/core";
import { FoodDiaryService } from 'src/app/services';

@Component({
  selector: 'food-diary-product-table-item-menu',
  templateUrl: './table-item-menu.component.html',
  styleUrls: ['./table-item-menu.component.less']
})

export class FoodDiaryProductTableItemMenuComponent {
  @Input() productId: number;
  @Input() tableId: number;

  constructor(
    private foodDiaryService: FoodDiaryService
  ) { }

  public deleteProduct() {
    this.foodDiaryService.deleteProductInTable(this.productId, this.tableId);
  }
  
}
