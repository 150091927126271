import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from "@angular/core";
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: "change-value",
  templateUrl: "./change-value.component.html",
  styleUrls:["./change-value.component.less"]
})

export class ChangeValueComponent {
  @ViewChild('inpValue', { static: false }) inpValue: ElementRef;

  @Input() label: string = '';
  @Input() set value(value:string) {
    this.valueFormControl.setValue(value);
  }
  @Output() resultValue: EventEmitter<string> = new EventEmitter();

  valueFormControl = new FormControl('', [Validators.required]);

  public isEdit: boolean = false;

  public showEdit() {
    this.isEdit = true;
    setTimeout(() => {
      this.inpValue.nativeElement.focus();
    });
  }

  public save() {
    this.resultValue.emit(this.valueFormControl.value);
    this.isEdit = false;
  }

  public cancel() {
    this.isEdit = false;
  }

}
