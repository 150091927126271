import { Component, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FoodDiaryService } from 'src/app/services';


@Component({
  selector: 'food-diary-page',
  templateUrl: './food-diary-page.component.html',
  styleUrls: ["./food-diary-page.component.less"]
})

export class FoodDiaryPageComponent implements OnInit {
  constructor(
    private activateRoute: ActivatedRoute,
    private foodDiaryService: FoodDiaryService
  ) {}

  public date = new FormControl({ value: new Date(), disabled: true });

  ngOnInit() {

    //this.foodDiaryService.loadCalorieAndPfcNormByDays();

    this.activateRoute.params.subscribe(params => {
      if (params['date'])
        this.date = new FormControl({ value: new Date(params['date']), disabled: true });
      
    });

    this.foodDiaryService.loadMealTypes();
  }
  
}
