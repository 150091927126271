import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Product, FoodDiaryMealTypes, FoodDiaryAddProductApi, ApiData, FoodDiaryTableProduct } from 'src/app/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FoodDiaryApiService, FoodDiaryService } from 'src/app/services';

@Component({
  selector: "food-diary-product-add-form",
  templateUrl: './add-form.component.html',
  styleUrls: ['./add-form.component.less']
})

export class FoodDiaryProductAddFormComponent implements OnInit {
  private _product: Product = new Product();

  @Input() set product(product: Product) {
    
    if (product)
      this.display = true;
    else
      this.display = false;

    this._product = product;
  }
  @Input() mealTypes: FoodDiaryMealTypes[] = [];
  @Input() date: string;
  @Output() add: EventEmitter<boolean> = new EventEmitter<boolean>()

  public display: boolean = false;
  public addForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private foodDiaryService: FoodDiaryService
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      weight: ['100', Validators.required],
      meal_id: ['', Validators.required]
    });
  }

  public onHide() {
    this.display = false;
    this.add.emit(null);
  }

  public onSubmitAddForm() {
    if (this.addForm.controls.weight.value > 0 && this.addForm.controls.meal_id.value) { 
      this.foodDiaryService.addProductInTable(new FoodDiaryAddProductApi(
        this.date,
        this.product.id,
        this.addForm.controls.weight.value,
        this.addForm.controls.meal_id.value
      ));
      this.display = false;
      this.add.emit(true);
    }
  }

  get product() {
    return this._product;
  }
}

