import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { FoodDiaryTables, FoodDiaryMealTypes, FoodDiaryTable } from 'src/app/models';
import { FormControl } from '@angular/forms';
import { FoodDiaryService } from 'src/app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'food-diary-product-tables',
  templateUrl: './tables.component.html',
  styleUrls:['./tables.component.less']
})

export class FoodDiaryProductTablesComponent implements OnInit, OnDestroy {
  private _date;
  private _subscriptions: Subscription[] = [];
  
  @Input() set date(date: FormControl) {
    this._date = date;
    
    if (this.mealTypes) 
      this.foodDiaryService.loadTables(date.value, this.mealTypes);
  }

  public tables: FoodDiaryTables[] = [];
  public total: FoodDiaryTable = new FoodDiaryTable();
  public mealTypes: FoodDiaryMealTypes[];
  
  constructor(
    private foodDiaryService: FoodDiaryService
  ) { }

  ngOnInit() {
    const mealTypesSub = this.foodDiaryService.getMealTypes().subscribe(
      (mealTypes: FoodDiaryMealTypes[]) => {
        this.mealTypes = mealTypes;
        this.foodDiaryService.loadTables(this.date.value, mealTypes);
      }
    );

    const tableSub = this.foodDiaryService.getTables().subscribe(
      (tables: FoodDiaryTables[]) => {
        this.tables = tables;
        this.total = this.foodDiaryService.totalDay;
      }
    );

    this._subscriptions.push(mealTypesSub);
    this._subscriptions.push(tableSub);
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  get strDate() {
    return this.foodDiaryService.getStrDate(this.date.value);
  }
  get beautifyDate() {
    return this.foodDiaryService.getBeautifyDate(this.date.value);
  }

  get date() {
    return this._date;
  }

  get emptyTables() {
    const empty = this.tables.findIndex(t => t.products.length>0);
    if (empty == -1)
      return true;
    else
      return false;
  }
  
}
