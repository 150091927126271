
export class User {
  email: string;
  name: string;
  token: string;
}

export class PersonalData {
  aim_id: number;
  birthday: string;
  body_type_id: number;
  desired_weight: number;
  gender_id: number;
  growth: number;
  norm_calories: number;
  norm_carbohydrate: number;
  norm_fat: number;
  norm_protein: number;
  user_id: number;
  weight: number;
  work_type_id: number;
}

export class PersonalDataNorm {
  calories: number;
  carbohydrate: number;
  fat: number;
  protein: number;
}
