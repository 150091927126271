import { Component, OnInit } from '@angular/core';
import { User } from './models';
import { AccountService, FoodDiaryService } from './services';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

  private _displaySideNav: boolean = true;
  public sidenavOpened: boolean = true;
  public mobileQuery: MediaQueryList;
  public currentUser: User;
  public copyrightNowYear: number;

  public set displaySidenav(status: boolean) {
    this._displaySideNav = status;
  }
  public get displaySidenav() {
    return this._displaySideNav;
  }
  
  constructor(
    private accountService: AccountService,
    private media: MediaMatcher,
    private foodDiaryService: FoodDiaryService
  ) {
    this.accountService.currentUser.subscribe((user: User) => this.currentUser = user);
    this.mobileQuery = media.matchMedia('(max-width: 600px)');

    if (this.mobileQuery.matches)
      this.sidenavOpened = false;
  }

  ngOnInit() {
    this.copyrightNowYear = new Date().getFullYear();
  }

  public getModeInSidenav() {
    if (this.mobileQuery.matches)
      return 'over';
    else
      return 'side';
  }

  public sidenavClosed(sidenav: MatSidenav) {
    if (this.mobileQuery.matches)
      sidenav.close();
  }
  
}
