import { NgModule } from '@angular/core';
import { PersonalDataPageComponent } from './personal-data-page.component';
import { SharedModule } from 'src/app/shared';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PersonalDataService } from 'src/app/services/personal-data.service';
import { OffsetTopDirective, ScrollableDirective } from 'src/app/directives';


@NgModule({
  declarations: [
    PersonalDataPageComponent,
    OffsetTopDirective,
    ScrollableDirective
  ],
  imports: [
    FlexLayoutModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    PersonalDataService
  ]
})

export class PersonalDataPageModule { }
