import { Component, Input, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { MatTableDataSource, MatSort } from '@angular/material';
import { FoodDiaryTableProduct, FoodDiaryTable, FoodDiaryMealTypes } from 'src/app/models';
import { FoodDiaryService } from 'src/app/services';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'food-diary-product-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.less']
})

export class FoodDiaryProductTableComponent implements AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() id: number;
  @Input() meal_id: number;
  @Input() total: FoodDiaryTable = new FoodDiaryTable();
  @Input() name: string;
  @Input() mealTypes: FoodDiaryMealTypes[] = [];
  @Input() date: FormControl;
  @Input() set data(data: FoodDiaryTableProduct[]) {
    
    this.dataSource = new MatTableDataSource(
      this.foodDiaryService.tableFormatting(data)
    );

    this.changeDetectorRefs.detectChanges();
  }
  
  public displayedColumns: string[] = [
    'name',
    'weight',
    'calories',
    'protein',
    'carbohydrate',
    'fat',
    'menu'
  ];
  public dataSource = new MatTableDataSource();

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private foodDiaryService: FoodDiaryService
  ) { }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public updateWeight(value, id: number) {
    this.foodDiaryService.updateWeightProductInTable(id, Number(value), this.id);
  }
}
