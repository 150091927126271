import { Component, Input, Output, EventEmitter } from "@angular/core";
import { User } from 'src/app/models';
import { AccountService } from 'src/app/services';
import { Router } from '@angular/router';

@Component({
  selector: "aside-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.less"]
})

export class AsideUserComponent {
  @Input() currentUser: User;
  @Output() sidenavClosed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private accountService: AccountService,
    private router: Router
  ) {}

  public logout() {
    this.sidenavClosed.emit(null);
    this.accountService.logout(this.currentUser);
    this.router.navigate(['/auth']);
    return false;
  }
  
}
