import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDatepickerInputEvent, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/helpers/app-date.adapter';
import { FoodDiaryService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { FoodDiaryDayStatus } from 'src/app/models';

@Component({
  selector: 'food-diary-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  providers: [
    //{ provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class FoodDiaryHeaderComponent implements OnInit, OnDestroy {
  @Input() date: FormControl;

  private _subscriptions: Subscription[] = [];
  //private totalAllDays: FoodDiaryDayStatus[];

  constructor(
    private router: Router,
    private foodDiaryService: FoodDiaryService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('ru');
  }

  ngOnInit() {
    //const sub = this.foodDiaryService.getTotalAllDays().subscribe(data => this.totalAllDays = data);

    //this._subscriptions.push(sub);
  }

  public onDateChange(event: MatDatepickerInputEvent<Date>) { 
    this.router.navigate(['food-diary', this.foodDiaryService.getStrDate(event.value) ]);
  }

  public dateClass = (d: Date) => {
    /*
    const tDay = this.totalAllDays.find(t => {

      let sD = t.date.split('-');
      let tD = new Date(Number(sD[0]), Number(sD[1])-1, Number(sD[2]));

      if (tD.getDate() == d.getDate() && tD.getMonth() == d.getMonth() && tD.getFullYear() == d.getFullYear())
        return true;
      else
        return false;
    });

    if (tDay)
      return 'filled-data-' + tDay.status;
    else*/
      return undefined;
     
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}
