import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { FoodDiaryAddProductApi, ApiData, Product } from '../models';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Injectable()
export class FoodDiaryApiService {
  constructor(
    private http: HttpClient,
    private conf: ConfigurationService
  ) { }

  public getTables(date: string) {
    return this.http.post(
      this.url + 'table', { date: date }
    );
  }

  public getMealTypes() {
    return this.http.get(
      this.url + 'table/get_meal_types'
    );
  }

  public getCategories(category_id: number) {
    return this.http.post(
      this.url + 'product/get_categories',
      { category_id: category_id }
    );
  }

  public getProducts(category_id: number) {
    return this.http.post(
      this.url + 'product/get_products',
      { category_id: category_id }
    );
  }
  public addProducts(data: FoodDiaryAddProductApi
  ) {
    return this.http.post(
      this.url + 'add',
      data
    );
  }
  public search(term) {
    return this.http.post(this.url + 'product/search', { term: term })
      .pipe(
        debounceTime(500),  // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map(
          (data: ApiData<Product[]>) => {
            if (data.data.length != 0)
              return data.data as Product[];
          }
        )
      );

  }
  public deleteProductInTable(id: number) {
    return this.http.post(
      this.url + 'table/delete_product',
      { id: id }
    );
  }

  public updateWeightProductInTable(id: number, weight:number) {
    return this.http.post(
      this.url + 'table/update_weight_product',
      { id: id, weight: weight }
    );
  }

  public getCalorieAndPfcNormByDays() {
    return this.http.get(
      this.url + 'report'
    );
  }

  public tableCopy(from_meal_id: number, to_meal_type_id: number, to_date: string) {
    return this.http.post(
      this.url + 'table/copy',
      { from_meal_id: from_meal_id, to_date: to_date, to_meal_type_id: to_meal_type_id }
    );
  }
  
  get url() {
    return this.conf.apiUrl + 'food_diary/';
  }
}
