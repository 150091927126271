import { Component, OnInit,  Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ConfigurationService, FoodDiaryApiService, FoodDiaryService } from 'src/app/services';
import { ApiData, DialogDataCopyFoodTable } from 'src/app/models';
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepickerInputEvent } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'dialog-copy-table-food',
  templateUrl: './dialog-copy-table.component.html',
  styleUrls: ['./dialog-copy-table.component.less']
})

export class FoodDiaryProductDialogCopyTableComponent implements OnInit {
  private from_date: Date;
  public copyTableForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private foodDiaryService: FoodDiaryService,
    private foodDiaryApiService: FoodDiaryApiService,
    private configurationService: ConfigurationService,
    private dialogRef: MatDialogRef<FoodDiaryProductDialogCopyTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCopyFoodTable,
    private router: Router
  ) {}

  ngOnInit() {
    this.copyTableForm = this.formBuilder.group({
      meal_id: [this.data.id, Validators.required]
    });
    this.from_date = this.data.date.value;
    
  }

  get f() { return this.copyTableForm.controls; }

  public onDateChange(event: MatDatepickerInputEvent<Date>) {
    //this.date = event.value;
  }

  public onSubmit() {
    if (this.copyTableForm.valid) {
      const date = this.foodDiaryService.getStrDate(this.data.date.value);
      const dateBeautify = this.foodDiaryService.getBeautifyDate(this.data.date.value);

      this.configurationService.setLoadingIndicator(true);
      
      this.foodDiaryApiService.tableCopy(
        this.data.meal_id,
        this.f.meal_id.value,
        date
      ).subscribe((result: ApiData<string>) => {
        if (result.data =='success') {
          this.configurationService.showMessage(
            "success",
            "Успешно скопировано",
            dateBeautify + " " + this.get_meal_name(this.f.meal_id.value)
          );
          if (date != this.foodDiaryService.getStrDate(this.from_date)) {
            this.router.navigate(['food-diary', date]);
          }
          else {//updated page. (load new table)
            this.foodDiaryService.loadTables(this.data.date.value, this.data.mealTypes);
          }

        }
        else if (result.error)
          this.configurationService.showMessage("error", result.error.text);

        this.closed();

        setTimeout(() => this.configurationService.setLoadingIndicator(false));
      },
        error => this.configurationService.httpErrorResponse(error)
      );
      
    }
  }

  public closed() {

    this.dialogRef.close();
  }

  private get_meal_name(id:number ) {
    const meal = this.data.mealTypes.find(m => m.id == id);
    return meal.name;
  }
}
