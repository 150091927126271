import { Component, Input, Output, EventEmitter } from "@angular/core";
import { User } from 'src/app/models';
import { MatDialog } from '@angular/material';
import { DialogAskQuestionComponent } from '../dialog/ask-question.component';

@Component({
  selector: "aside-menu",
  templateUrl: "./menu.component.html",
  styleUrls:["./menu.component.less"]
})

export class AsideMenuComponent {
  @Input() currentUser: User;
  @Output() sidenavClosed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public dialog: MatDialog
  ) { }

  public onSidenavClosed() {
    this.sidenavClosed.emit(null);
  }

  public openDialogAskQuestion() {
    this.onSidenavClosed();

    this.dialog.open(DialogAskQuestionComponent);

  }
}
