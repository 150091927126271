import { Component, Input } from "@angular/core";


@Component({
  selector: "form-field-error-display",
  templateUrl: "./field-error-display.component.html",
  styleUrls:["./field-error-display.component.less"]
})

export class FormFieldErrorDisplayComponent {
  @Input() errorMsg: string;
  @Input() displayError: boolean = false;
  @Input() isCommon: boolean = false;
}
