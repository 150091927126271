import { Component } from '@angular/core';

@Component({
  selector: "authorization-page",
  templateUrl: "./authorization-page.component.html",
  styleUrls: ["./authorization-page.component.less"]
})

export class AccountAuthorizationPageComponent{
  public selected: number = 0;//0 - авт, 1 - рег, 2- вост

  constructor() { }

  public selectedTab(index: number) {
    this.selected = index;
  }
}
