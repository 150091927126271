import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '.';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class AccountGuard implements CanActivate {

  constructor(
    @Inject(AccountService) private accountService: AccountService,
    private router: Router
  ) { }

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    
    if (!this.accountService.currentUserValue) {
      this.router.navigate(['auth']);
      return false;
    }

    return true;
  }
}
