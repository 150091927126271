import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { FoodDiaryTable, PersonalDataNorm } from 'src/app/models';


@Component({
  selector: 'chart-macronutrient-intake',
  templateUrl: './chart-macronutrient-intake.component.html',
  styleUrls: ['./chart-macronutrient-intake.component.less']
})

export class ChartMacronutrientIntakeComponent implements OnInit {
  private _norm: PersonalDataNorm;
  private _totalDay: FoodDiaryTable;
  @Input() set norm(norm: PersonalDataNorm) {
    this._norm = norm;
    this.setBarChartData();
  }
  @Input() set totalDay(totalDay: FoodDiaryTable) {
    this._totalDay = totalDay;
    this.setBarChartData();
  }

  public barChartOptions: (ChartOptions & { annotation: any });
  public barChartLabels: Label[] = ['Калории', 'Белки', 'Жиры', 'Углеводы'];
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = false;

  public barChartData: ChartDataSets[] = [];

  constructor() { }

  ngOnInit() {
    this.barChartOptions = {
      responsive: true,
      scales: {
        xAxes: [
          {
            id: 'x-axis-0',
            position: 'bottom',
            stacked: true,
            ticks: {
              max: 140,
              callback: (e) => {
                
                return e + "%";
              }
            }
          }
        ], yAxes: [
          {
            id: 'y-axis-0',
            position: 'left',
            stacked: true
          }
        ]
      },
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'horizontal',
            scaleID: 'x-axis-0',
            value: 50,
            borderColor: 'orange',
            borderWidth: 2,
            label: {
              enabled: true,
              fontColor: 'orange',
              content: 'LineAnno'
            }
          },
        ],
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
        }
      },
      tooltips: {
        
        
        callbacks: {
          label: (tooltipItem, data)=> {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';
            
            let value: number = <number>tooltipItem.xLabel;
            return tooltipItem.label + ": " + value.toFixed(0) + "%";
          }
        }
      }
    };
  }

  private setBarChartData() {
    if (this._norm && this._totalDay) {
      this.barChartData = [
        {data: [
          { y: 'Калории', x: this._totalDay.calories / this._norm.calories * 100 },
          { y: 'Белки', x: this._totalDay.protein / this._norm.protein * 100 },
          { y: 'Жиры', x: this._totalDay.fat / this._norm.fat * 100 },
          { y: 'Углеводы', x: this._totalDay.carbohydrate / this._norm.carbohydrate * 100 }
        ]}
      ];
    }
  }
}
