import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';


@Injectable()

export class OtherService {
  private readonly controllerName: string = 'other/';

  constructor(
    private http: HttpClient,
    private conf: ConfigurationService
  ) { }

  public sendAskQuestion(email: string, name: string, text: string) {
    return this.http.post(
      this.url + 'feedback/ask_question',
      { email: email, name: name, text: text }
    );
  }

  get url() {
    return this.conf.apiUrl + this.controllerName;
  }
}
