import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService, ConfigurationService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiData, matchOtherValidator, User } from 'src/app/models';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { AccountDialogPrivacyPolicyComponent } from './dialog-privacy-policy.component';

@Component({
  selector: "account-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.less"]
})
export class AccountRegistrationComponent implements OnInit {
  private returnUrl: string;
  public registrationForm: FormGroup;
  private formSumitAttempt: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute,
    private configurationService: ConfigurationService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.registrationForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password2: ['', matchOtherValidator('password')],
      name: ['', Validators.required],
      privacyPolicy: ['', Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.registrationForm.controls; }

  public onSubmit() {
    this.formSumitAttempt = true;
    
    if (this.registrationForm.valid) {
      this.configurationService.setLoadingIndicator(true);
      this.accountService.registration(
        this.f.email.value,
        this.f.password.value,
        this.f.name.value
      ).subscribe((result: ApiData<User>) => {
        if (result.data) {
          this.accountService.setCurrentUser(result.data);
          this.router.navigate([this.returnUrl]);
          this.configurationService.showMessage("success","Регистрация прошла успешно", "Добро пожаловать, " + result.data.name + "!");
        }
        else if (result.error) 
          this.configurationService.showMessage("error", result.error.text);

        setTimeout(() => this.configurationService.setLoadingIndicator(false));
        },
        (error: HttpErrorResponse) => this.configurationService.httpErrorResponse(error)
      );
    }
  }

  public isFieldValid(field: string) {
    return (
      (!this.registrationForm.get(field).valid && this.registrationForm.get(field).touched) ||
      (this.registrationForm.get(field).untouched && this.formSumitAttempt && !this.registrationForm.get(field).valid)
    );
  }

  public openDialogPrivacyPolicy() {
    this.dialog.open(AccountDialogPrivacyPolicyComponent);
  }

}





