import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ConfigurationService, AccountService, OtherService } from 'src/app/services';
import { User, ApiData } from 'src/app/models';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'dialog-ask-question',
  templateUrl: './ask-question.component.html',
  styleUrls: ['./ask-question.component.less']
})

export class DialogAskQuestionComponent implements OnInit {
  
  private formSumitAttempt: boolean;
  private currentUser: User;

  public askForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private otherService: OtherService,
    private configurationService: ConfigurationService,
    private dialogRef: MatDialogRef<DialogAskQuestionComponent>
  ) { }

  ngOnInit() {
    this.askForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      text: ['', Validators.required]
    });

    this.currentUser = this.accountService.currentUserValue;

    if (this.currentUser) {
      this.askForm.controls.email.setValue(this.currentUser.email);
      this.askForm.controls.name.setValue(this.currentUser.name);
    }

  }

  get f() { return this.askForm.controls; }

  public onSubmit() {
    this.formSumitAttempt = true;

    if (this.askForm.valid) {
      this.configurationService.setLoadingIndicator(true);
      this.otherService.sendAskQuestion(
        this.f.email.value,
        this.f.name.value,
        this.f.text.value
      ).subscribe((result: ApiData<string>) => {
        if (result.data =='success') {
          this.configurationService.showMessage("success", "Письмо было отправлено");
        }
        else if (result.error)
          this.configurationService.showMessage("error", result.error.text);

        this.closed();

        setTimeout(() => this.configurationService.setLoadingIndicator(false));
      },
        error => this.configurationService.httpErrorResponse(error)
      );
      
    }
  }

  public isFieldValid(field: string) {

    return (
      (!this.askForm.get(field).valid && this.askForm.get(field).touched) ||
      (this.askForm.get(field).untouched && this.formSumitAttempt && !this.askForm.get(field).valid)
    );
  }

  public closed() {

    this.dialogRef.close();
  }
}
