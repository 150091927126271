import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[totalStatus]'
})

export class TableTotalDirective implements OnChanges {
  @Input() totalStatusId:number;
  @Input() totalStatusValue:number;

  constructor(private elementRef: ElementRef) {
    
  }

  ngOnChanges() {
    if (this.totalStatusId == 2 && this.totalStatusValue) {
      if (this.totalStatusValue >= 90 && this.totalStatusValue < 110)
        this.elementRef.nativeElement.style.backgroundColor = "rgba(0,255,0,.5)";
      else if ((this.totalStatusValue >= 80 && this.totalStatusValue < 90) || (this.totalStatusValue >= 110 && this.totalStatusValue < 120))
        this.elementRef.nativeElement.style.backgroundColor = "rgba(0,0,255,.5)";
      else if ((this.totalStatusValue >= 40 && this.totalStatusValue < 80) || (this.totalStatusValue >= 120 && this.totalStatusValue < 140))
        this.elementRef.nativeElement.style.backgroundColor = "rgba(255,255,0,.5)";
      else if ((this.totalStatusValue >= 0 && this.totalStatusValue < 40) || (this.totalStatusValue >= 140))
        this.elementRef.nativeElement.style.backgroundColor = "rgba(255,0,0,.5)";
    }
  }

}
