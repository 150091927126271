import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Subject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ConfigurationService {
  private _subjectLoadingIndicator = new Subject<boolean>();

  public apiUrl: string = environment.apiUrl;

  constructor(private toastrService: ToastrService) { }

  public setLoadingIndicator(status:boolean) {
    this._subjectLoadingIndicator.next(status);
  }
  public getLoadingIndicator(): Observable<boolean> {
    return this._subjectLoadingIndicator.asObservable();
  }

  public showMessage(type, title?:string, message?:string) {
    switch (type) {
      case "success":
        this.toastrService.success(message, title);
        break;
      case "error":
        this.toastrService.error(message, title);
        break;
    }
  }

  public httpErrorResponse(error: HttpErrorResponse) {
      switch (error.status) {
        case 0:
          this.showMessage("error", "Неизвестная ошибка", "Проверьте подключения к интернету");
          break;
        case 200:
          this.showMessage("error", "Извините синтаксическая ошибка", "Пожалуйста сообщите нам об проблеме");
          break;
      }
    console.log(error);
    this.setLoadingIndicator(false);
  }
}
