import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService, ConfigurationService } from 'src/app/services';
import { ApiData, FormFieldError, User } from 'src/app/models';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'account-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})

export class AccountLoginComponent implements OnInit {

  @Output() selectedTab: EventEmitter<number> = new EventEmitter<number>();

  private returnUrl: string;
  private formSumitAttempt: boolean;
  public loginForm: FormGroup;
  

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute,
    private configurationService: ConfigurationService
  ) {
    if (this.accountService.currentUserValue)
      this.router.navigate(['/']);
  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.loginForm.controls; }

  isFieldValid(field: string) {
    return (
      (!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
      (this.loginForm.get(field).untouched && this.formSumitAttempt && !this.loginForm.get(field).valid)
    );
  }

  public onSubmitLogin() {
    this.formSumitAttempt = true;

    if (this.loginForm.valid) {

      this.configurationService.setLoadingIndicator(true);

      this.accountService.login(
        this.f.email.value, this.f.password.value
      ).subscribe((result: ApiData<User>) => {
          if (result.data) {
            this.accountService.setCurrentUser(result.data);
            this.router.navigate([this.returnUrl]);
            this.configurationService.showMessage("success", "Добро пожаловать, "+result.data.name+"!");
          }
          else if (result.error)
            this.configurationService.showMessage("error", result.error.text);
          
          setTimeout(() => this.configurationService.setLoadingIndicator(false));
        },
        (error: HttpErrorResponse) => this.configurationService.httpErrorResponse(error)
      );
    }
  }

  public setSelectedTab(index:number) {
    this.selectedTab.emit(index);
  }
  
}
