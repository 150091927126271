import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Product } from 'src/app/models';

@Component({
  selector: 'food-diary-product-add-list',
  templateUrl: './add-list.component.html',
  styleUrls: ['./add-list.component.less']
})

export class FoodDiaryProductAddListComponent {
  @Input() products: Product[] = [];
  @Output() selectProduct: EventEmitter<Product> = new EventEmitter<Product>();

  public select(product) {
    this.selectProduct.emit(product);
  }
}
