
export class ProductCategory {
  id: number;
  name: string;
  displayProduct: boolean = false;
  products: Product[] = null;
}

export class Product {
  public id: number;
  public name: string;
  public calories: number;
  public carbohydrate: number;
  public protein: number;
  public fat: number;

  constructor(
    id?: number,
    name?: string,
    calories?: number,
    carbohydrate?: number,
    protein?: number,
    fat?: number
  ) {

    this.id = id;
    this.name = name;
    this.calories = calories,
    this.carbohydrate = carbohydrate;
    this.protein = protein;
    this.fat = fat;
  }
}
