import { Component, OnInit, OnDestroy } from '@angular/core';
import { FoodDiaryService, AccountService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { FoodDiaryTable, ApiData, PersonalDataNorm, ApiDataError } from 'src/app/models';

@Component({
  selector: 'food-diary-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.less']
})

export class FoodDiaryReportComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  public totalDay: FoodDiaryTable = new FoodDiaryTable();
  public personalDataNorm: PersonalDataNorm = new PersonalDataNorm();
  constructor(
    private foodDiaryService: FoodDiaryService,
    private accountService: AccountService
  ) { }

  ngOnInit() {

    this.accountService.getNorm().subscribe(
      (norm: ApiData<PersonalDataNorm>) => {
        this.personalDataNorm = norm.data;
      },
      (error: ApiDataError) => console.log(error)
    );

    const totalDaySub = this.foodDiaryService.getTotalDay().subscribe(
      (totalDay: FoodDiaryTable) => {
        this.totalDay = totalDay;
      }
    );

    this._subscriptions.push(totalDaySub);
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}
