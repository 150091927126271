import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FoodDiaryService } from 'src/app/services';
import { ApiData, ProductCategory, Product, FoodDiaryMealTypes, FoodDiaryAddProductApi, FoodDiaryTableProduct } from 'src/app/models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';


@Component({
  selector:'food-diary-product-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.less']
})

export class FoodDiaryProductAddComponent{
  @Input() mealTypes: FoodDiaryMealTypes[] = [];
  @Input() date: string;

  public displayAdd: boolean = false;
  public displayCategory: boolean = true;
  
  public selectedProduct: Product = null;
  public products: Product[] = [];

  public showAdd() {
    this.displayAdd = true;
  }

  public hideAdd() {
    this.displayAdd = false;
    this.selectedProduct = null;
  }

  public selectProduct(product: Product) {
    this.selectedProduct = product;
  }

  public addProduct(product: boolean) {
    if (product) {
      this.displayAdd = false;
    }
    else this.selectedProduct = null;
  }

  public filterProducts(product: Product[]) {
    if (product) {
      this.displayCategory = false;
      this.products = product;
    }
    else
      this.displayCategory = true;
  }
}
