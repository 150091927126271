import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { User, PersonalData } from '../models';

@Injectable()
export class AccountService {
  private readonly controllerName: string = 'account/';
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private conf: ConfigurationService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public login(email:string,password:string) {
    
    return this.http.post(
      this.url + 'authorization',
      { email: email, password: password }
    );
  }

  public setCurrentUser(user: User) {
    //console.log(user);
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  public logout(currentUser:User) {

    this.http.post(this.url + 'authorization/logout',
      { email: currentUser.email, token: currentUser.token }
    ).subscribe(
      data => { },
      error => console.log(error)
    );

    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  public registration(email: string, password: string, name: string) {
    return this.http.post(
      this.url + 'registration',
      {email:email, password:password, name:name}
    );
  }

  public restorePassword(email: string) {
    return this.http.post(
      this.url + 'registration/restore_password',
      { email: email }
    );
  }

  public updatePersonalData(personal_data: PersonalData, isUpdateNorm:boolean) {
    let birthday: Date = new Date(personal_data.birthday);

    personal_data.birthday =
      birthday.getFullYear() + '-' +
      (birthday.getMonth() + 1) + '-' +
      birthday.getDate();

    return this.http.post(
      this.url + 'personal_data/update',
      { personal_data: JSON.stringify(personal_data), is_update_norm: isUpdateNorm }
    );
  }

  public updatePersonalDataNorm(
    norm_calories: number,
    norm_carbohydrate: number,
    norm_fat: number,
    norm_protein: number
  ) {
    return this.http.post(
      this.url + 'personal_data/update_norm',
      {
        norm_calories: norm_calories,
        norm_carbohydrate: norm_carbohydrate,
        norm_fat: norm_fat,
        norm_protein: norm_protein
      }
    );
  }

  public getPersonalData() {
    return this.http.get(
      this.url + 'personal_data'
    )
  }

  public getNorm() {
    return this.http.get(
      this.url + 'personal_data/get_norm'
    )
  }

  get url() {
    return this.conf.apiUrl + this.controllerName;
  }

}
