import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FoodDiaryProductDialogCopyTableComponent } from './dialog-copy-table.component';
import { FoodDiaryMealTypes } from 'src/app/models';
import { FormControl } from '@angular/forms';


@Component({
  selector: "food-diary-product-table-menu",
  templateUrl: "./table-menu.component.html",
  styleUrls: ["./table-menu.component.less"]
})

export class FoodDiaryProductTableMenuComponent {
  @Input() id: number;
  @Input() meal_id: number;
  @Input() mealTypes: FoodDiaryMealTypes[] = [];
  @Input() date: FormControl;

  constructor(
    public dialog: MatDialog
  ) {}

  public openDialogCopyTableFood() {
    this.dialog.open(FoodDiaryProductDialogCopyTableComponent, {
      data: {
        mealTypes: this.mealTypes,
        date: this.date,
        id: this.id,
        meal_id: this.meal_id
      }
    });
  }
}
