import { Component } from "@angular/core";

@Component({
  selector: 'not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.less']
})

export class NotFoundPageComponent {

}
