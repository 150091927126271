import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ProductCategory, ApiData, Product } from 'src/app/models';
import { FoodDiaryApiService } from 'src/app/services';

@Component({
  selector: 'food-diary-product-add-categories',
  templateUrl: './add-categories.component.html',
  styleUrls: ['./add-categories.component.less']
})

export class FoodDiaryProductAddCategoriesComponent {

  @Input() set displayAdd(displayAdd: boolean) {
    if (displayAdd && this.categories.length == 0)
      this.loadDataCategories();
  }

  @Output() selectProduct: EventEmitter<Product> = new EventEmitter<Product>();

  public categories: ProductCategory[] = [];

  constructor(
    private foodDiaryService: FoodDiaryApiService
  ) { }


  public showProducts(category_id: number, index: number) {
    this.categories[index].displayProduct = true;

    if (this.emptyProductsInCategory(category_id))
      this.loadDataProducts(category_id);

    return false;
  }

  public onSelectProduct(product: Product) {
    this.selectProduct.emit(product);
    return false;
  }

  public hideProducts(index: number) {
    this.categories[index].displayProduct = false;
    return false;
  }

  private emptyProductsInCategory(category_id: number) {
    let findIndex: number = this.categories.findIndex(
      (category) =>
        category.id == category_id &&
        category.products == null
    );

    if (-1 != findIndex)
      return true;
    else
      return false;
  }

  private loadDataCategories() {
    this.foodDiaryService.getCategories(1).subscribe(
      (data: ApiData<ProductCategory[]>) => this.categories = data.data
    );
  }

  private loadDataProducts(category_id: number) {
    this.foodDiaryService.getProducts(category_id).subscribe(
      (data: ApiData<Product[]>) => {
        this.categories.map((category) => {
          if (category.id == category_id)
            category.products = data.data;
        });

      }
    );
  }

}
