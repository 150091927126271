import { NgModule } from "@angular/core";
import { FoodDiaryPageComponent } from './food-diary-page.component';
import { SharedModule } from 'src/app/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FoodDiaryHeaderComponent } from './header.component';
import {
  FoodDiaryProductAddComponent, FoodDiaryProductTableComponent, FoodDiaryProductTotalComponent,
  FoodDiaryProductTablesComponent, FoodDiaryProductAddListComponent, FoodDiaryProductAddFormComponent,
  FoodDiaryProductAddFilterComponent, FoodDiaryProductAddCategoriesComponent, FoodDiaryProductTableItemMenuComponent, FoodDiaryProductDialogCopyTableComponent, FoodDiaryProductTableMenuComponent
} from './product';
import { OrderPipe } from 'src/app/pipes/order-by.pipe';
import { FoodDiaryService, FoodDiaryApiService } from 'src/app/services';
import { ChartsModule } from 'ng2-charts';
import { FoodDiaryReportComponent } from './report/report.component';
import { ChartBfcCalorieRationComponent } from './report/chart-bfc-calorie-ratio.component';
import { ChartMacronutrientIntakeComponent } from './report/chart-macronutrient-intake.component';
import { ReportTotalComponent } from './report/table-total.component';
import { TableTotalDirective } from './report/table-total.directive';

@NgModule({
  declarations: [
    FoodDiaryPageComponent,
    FoodDiaryProductAddComponent,
    FoodDiaryProductTableComponent,
    FoodDiaryProductTotalComponent,
    FoodDiaryHeaderComponent,
    FoodDiaryProductTablesComponent,
    FoodDiaryProductAddListComponent,
    FoodDiaryProductAddFormComponent,
    FoodDiaryProductAddFilterComponent,
    FoodDiaryProductAddCategoriesComponent,
    FoodDiaryProductTableMenuComponent,
    FoodDiaryProductTableItemMenuComponent,
    FoodDiaryReportComponent,
    ChartBfcCalorieRationComponent,
    ChartMacronutrientIntakeComponent,
    OrderPipe,
    ReportTotalComponent,
    TableTotalDirective,
    FoodDiaryProductDialogCopyTableComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ChartsModule,
    SharedModule
  ],
  exports: [
    OrderPipe
  ],
  entryComponents: [
    FoodDiaryProductDialogCopyTableComponent
  ],
  providers: [
    FoodDiaryService,
    FoodDiaryApiService
  ]
})

export class FoodDiaryPageModule {}
